html {
  /** 
  rem 相对于根元素html的font-size
  em 相对于父元素的font-size
  浏览器默信的font-size 16px
  16 * 62.5 = 10
  1 rem === 10px
   */
  font-size: 62.5%;
}

html body #root .App {
  // viewport height === vh
  min-height: 100vh;
}

@primary-color: rgb(0, 80, 204);@font-size-base: 16px;